import React from 'react';
import { NotFound, Seo, SeoHidden } from 'components';

export default () => {
  return (
    <>
      <Seo title={'404 | cтраница не найдена'} description={'Этой страницы больше нет или никогда не было на сайте.'} />
      <NotFound />
      <SeoHidden>
          <h1>Ошибка 404</h1>
      </SeoHidden>
    </>
  );
};
